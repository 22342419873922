<nav class="navbar navbar-expand-lg navbar-light bg-light" id="nav">
    <div class="container-fluid">
        <div class="logo">
            <a href="https://scrimfinder.gg"><h3>Scrimfinder</h3></a>
        </div>
        <button class="navbar-toggler nav__toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a href="#home" class="nav-link">Home</a></li>
                <li class="nav-item"><a href="#about" class="nav-link">About</a></li>
                <li class="nav-item"><a href="#features" class="nav-link">Features</a></li>
                <li class="nav-item"><a href="https://docs.scrimfinder.gg" class="nav-link">Docs</a></li>
                <li class="nav-item"><a href="#contact" class="nav-link">Contact</a></li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="option-item">
                        <a href="https://docs.scrimfinder.gg/invite" class="default-btn">Invite The Bot<span></span></a>
                    </div>
                </div>
            </div>
            <hr style="color: #ff7700;">
            </div>
        </div>


        
    
</nav>

<!-- Path: src/app/components/common/navbar/navbar.component.ts -->