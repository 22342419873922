// cookie-banner.component.ts

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  acceptedCookies: boolean = false;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    const cookieBox = document.getElementById('js-cookie-box');
    const cookiesAccepted = this.cookieService.get('cookiesAccepted');
    if (cookiesAccepted) {
      cookieBox.style.display = 'none';
      
    }
    cookieBox.style.display = 'block';
  }

  acceptCookies() {
    const cookieBox = document.getElementById('js-cookie-box');
    this.acceptedCookies = true;
    this.cookieService.set('cookiesAccepted', 'true');
    cookieBox.style.display = 'none';
  }

  closeBanner() {
    const cookieBox = document.getElementById('js-cookie-box');
    this.acceptedCookies = false;
    this.cookieService.set('cookiesAccepted', 'false');
    cookieBox.style.display = 'none';
  }
}


