<div class="container">
    <div class="section-title">
        <h2>About Scrimfinder</h2>
        <div class="bar"></div>
        <p>Scrimfinder is a project of the Event Management Division League. After the Eventmanagement got closed the Media Agency <a href="https://newcreativ.de">New Creativ</a> started managing and developing the Bot.</p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>What is Scrimfinder?</h3>
                <div class="bar"></div>
                <p>The Idee of Scrimfinder is to simplify the process of serching for a Scrim. Its main purpos is to connect the Discords of all the diffrent Clans, Organisations and Clubs and allows them to serch for a Scrim in every Server the Bot is on.</p>
                <p>If you see a Scrimrequest you like, you can simply klick on the contact Button and write the Person a dm.</p>
                <div class="about-btn">
                    <a href="https://docs.scrimfinder.gg/invite" class="default-btn">Invite Scrimfinder<span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="https://maierfabian.de/images/happypingu.png" style="width: 500px;" alt="image">
            </div>
        </div>
    </div>
</div>