import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ThanksComponent } from './components/pages/thanks/thanks.component';
import {NewstuffV21Component } from './components/pages/newstuff-v2.1./newstuff-v2.1..component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'single-blog', component: BlogDetailsComponent},
    {path: 'thanks', component: ThanksComponent},
    {path: 'article/newstuff-v2.1.', component: NewstuffV21Component},
    { path: '**', component: ErrorPageComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }