<div class="subscribe-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe-content">
                    <h2>Subscribe for our Newsletter</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your Email" name="EMAIL" required autocomplete="off">
                    <button type="submit">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>
</div>