<div class="container">
    <div class="section-title">
        <h2>Testimonials</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div>

    <div class="testimonials-slides">
        <div class="client-feedback">
            <div>
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
                
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                    </div>
                </div>
            </div>
            
            <button class="prev-arrow slick-arrow">
                <i class="fa fa-chevron-left"></i>
            </button>
            
            <button class="next-arrow slick-arrow">
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>
        
        <div class="client-thumbnails">
            <div>
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/1.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Jonus Nathan</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/2.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Sadio Finn</h3>
                        <span>CEO at FlatIcon</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/3.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Tom Olivar</h3>
                        <span>CEO at ThemeForest</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/4.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>James Finn</h3>
                        <span>CEO at GitLab</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/5.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>John Lucy</h3>
                        <span>CEO at Linkedin</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/1.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Twitter</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/2.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>James Anderson</h3>
                        <span>CEO at Facebook</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/3.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Steven Smith</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>