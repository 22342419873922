import { Component } from '@angular/core';

@Component({
  selector: 'app-newstuff-v2.1.',
  standalone: true,
  imports: [],
  templateUrl: './newstuff-v2.1..component.html',
  styleUrl: './newstuff-v2.1..component.scss'
})
export class NewstuffV21Component {

}
