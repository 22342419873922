<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="https://scrimfinder.gg" class="logo"><h2>Scrimfinder</h2></a>
                    <p>Grow together and lets make Scrimserching easy again!</p>
                    <ul class="social-list">
                        <li><a href="https://twitter.com/scrimfinderDE" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://discord.gg/division-league-833783529506078781" target="_blank"><i class="fab fa-discord"></i></a></li>
                        <li><a href="https://github.com/foxyyyyyyyyyyyyy" target="_blank"><i class="fab fa-github"></i></a></li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>About</h3>

                    <ul class="list">
                        <li><a href="https://newcreativ.de">NewCreativ</a></li>
                        <li><a href="#premium">Premium</a></li>
                        <li><a href="https://docs.scrimfinder.gg/invite">Invite Scrimfinder</a></li>
                        <li><a href="https://newcreativ.de/impressum">Impressum</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Usefull</h3>

                    <ul class="list">
                        <li><a href="https://docs.scrimfinder.gg/">Documentation</a></li>
                        <li><a href="https://docs.scrimfinder.gg/guides/getting-started">Getting Started</a></li>
                        <li><a href="https://discord.gg/division-league-833783529506078781">Support Server</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Important Links</h3>
                    
                    <ul class="footer-holder">
                        <li><div class="option-item">
                            <a href="https://docs.scrimfinder.gg/invite" class="default-btn">Invite Scrimfinder<span></span></a>
                        </div></li>
                        <li><div class="option-item">
                            <a href="https://newcreativ.de" class="default-btn">&nbsp;&nbsp;&nbsp;&nbsp;NewCreativ&nbsp;&nbsp;&nbsp;&nbsp;<span></span></a>
                        </div></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>© Scrimfinder powerd by <a href="https://newcreativ.de" target="_blank">NewCreativ</a></p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>