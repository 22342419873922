<div class="container">
    <div class="section-title">
        <h2>Our Features</h2>
        <div class="bar"></div>
        <p>Lets take a look at Scrimfinders features</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-sun"></i>
                </div>
                <h3>Simple Design</h3>
                <p>With a simple but modern Design the Bot fits on every Server. With one of our premium plans you can even whitelabel your Bot.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa-solid fa-gamepad"></i>                </div>
                <h3>Moderated by RCS</h3>
                <p>Scrimfinder is proudly partnered with the Rainbow Class System. We are looking forward to further implement the Class System functions</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-desktop"></i>
                </div>
                <h3>Full Supported</h3>
                <p>The Scrimfinder Bot is fully Supported. If you need any help you can look it up in the docs or drop us a message.</p>
            </div>
        </div>

        

        
    </div>
</div>