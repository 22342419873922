<div class="container">
    <div class="section-title">
        <h2>Premium Plans</h2>
        <div class="bar"></div>
        <p>You don't need a premium Plan to use Scrimfinder. Every command is free and ever will be. But if you decides to support us and the Project or you want the Bot in your Design, you can Check out our Premium Plans.</p>
    </div>

    <div class="tab pricing-list-tab">
        <ul class="tabs">
            <li><a href="#">Monthly</a></li>
            <li><a href="#">Yearly</a></li>
        </ul>

        <div class="tab_content">
            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                0<sup>€</sup> <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> All bot features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> always the newest Updates</li>
                                <li><i class="fas fa-check"></i> Best Support Possible</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://docs.scrimfinder.gg/invite" class="default-btn">&nbsp;Invite&nbsp; <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Whitelabel</h3>
                            </div>

                            <div class="price">
                                ?<sup>€</sup> <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i>???</li>
                                <li><i class="fas fa-check"></i>???</li>
                                <li><i class="fas fa-check"></i>???</li>
                                <li><i class="fa fa-check"></i>???</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://discord.gg/division-league-833783529506078781" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Custom Bot</h3>
                            </div>

                            <div class="price">
                                10<sup>€</sup> <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> All bot features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> always the newest Updates</li>
                                <li><i class="fa fa-check"></i> Custom Design</li>
                                <li><i class="fa fa-check"></i> Own System bot for your Server*</li>
                                <li><i class="fa fa-check"></i> Prioritised Support</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://discord.gg/division-league-833783529506078781" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                0<sup>€</sup> <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> All bot features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> always the newest Updates</li>
                                <li><i class="fa fa-times"></i> Custom Design</li>
                                <li><i class="fa fa-times"></i> Own System bot for your Server*</li>
                                <li><i class="fa fa-times"></i> Prioritised Support</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://docs.scrimfinder.gg/invite" class="default-btn">&nbsp;Invite &nbsp; <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Whitelabel</h3>
                            </div>

                            <div class="price">
                                50<sup>€</sup> <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> All bot features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> always the newest Updates</li>
                                <li><i class="fa fa-check"></i> Custom Design</li>
                                <li><i class="fa fa-times"></i> Own System bot for your Server*</li>
                                <li><i class="fa fa-check"></i> Prioritised Support</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://discord.gg/division-league-833783529506078781" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Custom Bot</h3>
                            </div>

                            <div class="price">
                                99<sup>€</sup> <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> All bot features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> always the newest Updates</li>
                                <li><i class="fa fa-check"></i> Custom Design</li>
                                <li><i class="fa fa-check"></i> Own System bot for your Server*</li>
                                <li><i class="fa fa-check"></i> Prioritised Support</li>
                            </ul>

                            <div class="pricing-btn">
                                <a href="https://discord.gg/division-league-833783529506078781" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="note">* please write us on Discord or Mail to get informed, what functions the Systembot has. <br>
        We do not and will never make any main functions of the bot Premium.</p>
    </div>
</div>