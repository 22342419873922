<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/single-blog"><img src="https://fakeimg.pl/600x400" alt="image"></a>
                        <div class="category-btn">
                            <a routerLink="/blog">Update</a>
                        </div>
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> 12 April 2024</li>
                            <li><i class="fa fa-comments"></i> <a routerLink="/article/newstuff">3 Comment</a></li>
                        </ul>
                        <h3><a href="/newstuff" routerLink="/article/newstuff">Many new things! V.2.1.</a></h3>
                        <p>We added cool new Stuff!</p>
                        <a href="/newstuff-v2.1."   routerLink="/article/newstuff" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            
<!--
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog" class="prev page-numbers"><i class="fa fa-chevron-left"></i></a>
                    <a routerLink="/blog" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="fa fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>

    
</section>-->