import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})



export class ErrorPageComponent implements AfterViewInit {
  @ViewChild('countdown') countdown: ElementRef;

  ngAfterViewInit() {
    const countdownValue = 10;
    interval(1000)
      .pipe(take(countdownValue))
      .subscribe((value) => {
        this.countdown.nativeElement.textContent = countdownValue - value;
        if (value === countdownValue - 1) {
          window.location.href = '/';
        }
      });
  }
}