<section id="cookies" class="cookie-banner pt-100 pb-70">
    <app-cookie-banner></app-cookie-banner>

    
</section>
<div  class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>FIND SCRIMS EASIER THEN EVER!</h1>
                            <p>Together for a better together.<br> Find scrims by using the scrimfinder bot on discord!</p>
                            <div class="banner-holder">
                                <div class="option-item">
                                    <a href="https://docs.scrimfinder.gg/invite" class="default-btn">Invite The Bot<span></span></a>
                               
                                
                                    <a href="https://docs.scrimfinder.gg" class="default-btn">Check out The Docs<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            
                            <img src="https://maierfabian.de/images/hipingu.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</div>

<section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="about" class="about-area pb-100">
    <app-about></app-about>

    
</section>

<section id="features" class="features-area pb-70">
    <app-features></app-features>

    
</section>
<!--section id="how-it-works" class="how-it-works-area pb-70">
    <app-how-it-works></app-how-it-works>

    
</!--section>-->

<!--
<section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    
</section>
-->
<section class="overview-area ptb-100">
    <app-overview></app-overview>
</section>
<!--
<section id="premium" class="pricing-area pt-100 pb-70">
    <app-pricing></app-pricing>

    
</section>-->








<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    
</section>

