<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="overview-content">
                <h3>Grow Together with us!</h3>
                <div class="bar"></div>
                <p>We are just at the beginning! We would like to see you on our journey to make the eSport more connected!</p>
                <div class="overview-btn">
                    <a href="https://docs.scrimfinder.gg/invite" class="default-btn">Invite the Bot <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="overview-image">
                <img src="https://maierfabian.de/images/lovepingu.png" alt="Emote" height="50%" width="75%">
            </div>
        </div>
    </div>
</div>