<!-- cookie-banner.component.html 

<div id="js-cookie-box" class="cookie-box cookie-box--hide">
  Indulge in a sweeter browsing experience! This website uses cookies to enhance your journey. By continuing, you agree to our cookie policy. 🍪 <span id="js-cookie-button" class="cookie-button" (click)="acceptCookies()">Accept</span>
</div>

 <!--<button (click)="acceptCookies()">Cookies akzeptieren</button>
    <button (click)="closeBanner()">Banner schließen</button>--> 
    <div class="popup" id="js-cookie-box">
      <div class="popup__header"></div>
      <div class="popup__body">
        <img src="https://maierfabian.de/images/cookiesbigger.png" class="popup__image">
        <p class="popup__text">You want cookies? <br> We use cookies to ensure a better browser experience!</p>
      </div>
      <div class="popup__footer">
        <button class="popup__button" (click)="acceptCookies()">Give me Cookies!</button>
        <a  alt='Learn more' class="popup__link" href="">LEARN MORE</a>
      </div>
    </div>