<div class="container">
    <div class="section-title">
        <h2>Contact us</h2>
        <div class="bar"></div>
        <p>Feel free to contact us if you have any issues or want to apply for a sponsorship.</p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject*">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message*"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="default-btn" onclick="sendingerror()">Send Message<span></span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Check out our Discord for faster Support or write us an Mail.</h3>
                    <h2>
                        <a href="https://discord.gg/division-league-833783529506078781">Write us on Discord</a>
                        <span>Or</span>
                        <a href="mailto:hello&#64;newcreativ.de">hello&#64;newcreativ.de</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://twitter.com/scrimfinderDE" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<script>
    function sendingerror() {
        alert("The contact form is currently not working. Please use the Discord link or the mail link to contact us.");
    }  
</script>